<template>
  <span class="courseTitle">{{ title }}</span>
</template>

<script>

export default {
  name: "Header",
  props: {
    title: {
      required: true,
      type: String,
    },

  },
  data() {
    return {

    };
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.courseTitle{
  display: block;
  // width:100%;
  line-height: 60px;
  padding: 0 20px;
  height: 60px;
  background: url('../../../assets/hot_re_Bg.png') left top no-repeat #fff;
  font-weight: bold;
}
.el{
  padding: 10px 20px;
  margin-top: 0px !important;
}
</style>
