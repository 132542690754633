<template>
  <div class="invoice">
    <div style="text-align: left;">
      <Head title="发票管理"></Head>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 可开票订单 -->
      <el-tab-pane label="开票" name="first">

        <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="productName" label="商品名称"></el-table-column>
          <el-table-column prop="orderId" label="订单号">
          </el-table-column>
          <el-table-column label="金额">
            <template slot-scope="scope">
              <p class="red">¥ {{ scope.row.orderPrice }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="createAt" label="交易时间">
          </el-table-column>
          <!--          <el-table-column prop="Operation" label="操作"  width="104">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-button-->
          <!--                type="primary"-->
          <!--                size="small"-->
          <!--                @click="handleRequestInvoice(scope.row.id)"-->
          <!--                >申请发票</el-button-->
          <!--              >-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <el-button
            type="warning"
            size="small"
            style="float: right; margin: 15px 0"
            @click="handleRequestInvoice"
        >开发票
        </el-button
        >
        <!-- <div style="margin-top: 20px">
          <el-button @click="toggleSelection()" style="float: left"
            >取消选择</el-button
          >
        </div> -->

        <!-- 计算属性计算已选中单数以及价格 -->
      </el-tab-pane>
      <!-- 开票申请中 -->
      <el-tab-pane label="开票审核" name="second">
        <el-table
            ref="multipleTable"
            :data="tableData2"
            border
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="index" width="50" label="序号"/>
          <!--          <el-table-column prop="InvoiceType" label="发票类型">-->
          <!--            <template slot-scope="scope">-->
          <!--              <p>{{ scope.row.invoiceHeaderObj.invoiceHeader==1?"个人":"公司"}}</p>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column prop="orderId" label="订单编号">
            <template slot-scope="scope">
              <p>{{ showOrderNo(scope.row) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="InvoiceAmount" label="开票金额(元)">
            <template slot-scope="scope">
              <p class="red">¥ {{ scope.row.amount }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="ApplicationTime" label="申请时间">
            <template slot-scope="scope">
              <p>{{ scope.row.invoiceHeaderObj.createAt }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="ApplicationStatus" label="审核状态">
            <template slot-scope="scope">
              <p>{{ scope.row.auditStatusName }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="Operation" label="操作" width="104">
            <template slot-scope="scope">
              <el-button
                  type="primary"
                  size="small"
                  @click="handleViewInvoice(scope.row)"
              >查看
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="已开票" name="third">
        <el-table
            ref="multipleTable"
            :data="tableData3"
            tooltip-effect="dark"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="index" width="50" label="序号"/>
          <el-table-column prop="orderId" label="订单编号">
            <template slot-scope="scope">
              <p>{{ showOrderNo(scope.row) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="InvoiceAmount" label="开票金额(元)">
            <template slot-scope="scope">
              <p class="red">¥ {{ scope.row.amount }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="ApplicationTime" label="申请时间">
            <template slot-scope="scope">
              <p>{{ scope.row.invoiceHeaderObj.createAt }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="ApplicationStatus" label="审核状态">
            <template slot-scope="scope">
              <p>{{ scope.row.auditStatusName }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="Operation" label="操作" width="104">
            <template slot-scope="scope">
              <el-button
                  type="primary"
                  size="small"
                  @click="handleViewInvoice(scope.row)"
              >查看
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <div class="pagination">
        <Pagination
            :total="search.total"
            :page.sync="search.page"
            :limit.sync="search.size"
            @pagination="getFinishDataList"
        />
      </div>
    </el-tabs>
    <!-- 开票弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%" @close="resetForm">
      <div class="invoice-form">
        <div class="invoice-form__header">
          <!--          <h2 class="invoice-form__title">填写开票信息</h2>-->
          <p class="invoice-form__note">该开票为普通电子发票，请认真填写开票信息、仔细核对，发票不可合并跨年申请！</p>
        </div>
        <el-form :model="invoiceForm" :rules="rules" ref="invoiceForm" label-width="120px" class="invoice-form__body">
          <el-form-item label="发票抬头名称" prop="headerName">
            <el-input v-model="invoiceForm.headerName" placeholder="请输入发票抬头名称"></el-input>
          </el-form-item>
          <el-form-item label="企业税号" prop="taxNo">
            <el-input v-model="invoiceForm.taxNo" placeholder="请输入企业税号"></el-input>
          </el-form-item>
          <el-form-item label="企业地址" prop="companyAddress">
            <el-input v-model="invoiceForm.companyAddress" placeholder="请输入企业地址"></el-input>
          </el-form-item>
          <el-form-item label="企业电话" prop="companyPhone">
            <el-input v-model="invoiceForm.companyPhone" placeholder="请输入企业电话"></el-input>
          </el-form-item>
          <el-form-item label="开户银行名称" prop="bankName">
            <el-input v-model="invoiceForm.bankName" placeholder="请输入开户银行"></el-input>
          </el-form-item>
          <el-form-item label="银行账户" prop="bankAccount">
            <el-input v-model="invoiceForm.bankAccount" placeholder="请输入银行账户"></el-input>
          </el-form-item>
          <!--          <el-form-item label="发票类型" prop="isCompany">-->
          <!--            <el-radio v-model="invoiceForm.isCompany" :label=2>公司</el-radio>-->
          <!--            <el-radio v-model="invoiceForm.isCompany" :label=1>个人</el-radio>-->
          <!--          </el-form-item>-->

          <el-form-item label="姓名" prop="name">
            <el-input v-model="invoiceForm.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="QQ邮箱" prop="email">
            <el-input v-model="invoiceForm.email" placeholder="请输入收票邮箱"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input v-model="invoiceForm.idCard" placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="invoiceForm.phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="开票商品" prop="goods">
            <table class="invoice-form__table">
              <thead>
              <tr>
                <th>商品名称</th>
                <th>商品单价(元)</th>
                <th>购买份数</th>
                <th>订单金额(元)</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in invoiceForm.targetsObj" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.productPrice }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.payPrice }}</td>
              </tr>
              </tbody>
            </table>
          </el-form-item>
          <el-form-item label="开票金额(元)" prop="amount">
            <el-input v-model="invoiceForm.amount" placeholder="请输入开票金额"></el-input>
          </el-form-item>
          <el-form-item label="审核意见" v-if="invoiceForm.auditRemark" prop="review">
            <el-input disabled type="textarea" v-model="invoiceForm.auditRemark"
                      placeholder="请输入审核意见"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="comment">
            <el-input type="textarea" v-model="invoiceForm.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item label="审核意见" v-if="invoiceForm.auditComment" prop="review">
            <el-input v-model="invoiceForm.review" placeholder="请输入审核意见"></el-input>
          </el-form-item>
        </el-form>
        <div class="invoice-form__footer">
          <el-button v-if="invoiceForm.canSubmit" type="primary" @click="submitForm">提交</el-button>
          <el-button @click="resetForm">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderList,
} from "@/api/order";
import {
  submitInvoice,
  getInvoiceList,
  basicData,
} from "@/api/invoice";
import Pagination from "@/components/Pagination";
import invoice from "@/views/myCenter/components/Invoice.vue";
import Head from './Head'

export default {
  name: "Invoice",
  computed: {
    invoice() {
      return invoice
    }
  },
  components: {
    Pagination,
    Head
  },
  data() {
    return {
      activeName: "first", //选中状态
      dialogTitle: "增值税电子普通发票",
      dialogVisible: false,
      restaurants: [],
      basicData: {
        name: "",
        email: "",
        idCard: "",
        phone: "",
      },
      state: "",
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      invoiceForm: {
        headerName: "", //发票抬头名称
        isCompany: 1, //是否为单位
        taxNo: "", //单位税号
        name: "", //姓名
        idCard: "", //身份证号
        phone: "", //联系电话
        goods: "", //开票商品
        amount: "", //开票金额
        email: "", //收票邮箱
        remark: "", //备注
        auditComment: "", //审核意见
        targetsObj: [], //开票商品
        canSubmit: true, //审核状态
      },
      rules: {
        headerName: [
          {required: true, message: '请输入发票抬头名称', trigger: 'blur'},
        ],
        bankAccount: [
          {required: true, message: '请输入开户行账号', trigger: 'blur'},
        ],
        bankName: [
          {required: true, message: '请输入开户行名称', trigger: 'blur'},
        ],
        companyPhone: [
          {required: true, message: '请输入企业电话', trigger: 'blur'},
        ],
        taxNo: [
          {required: true, message: '请输入税号', trigger: 'blur'},
        ],
        companyAddress: [
          {required: true, message: '请输入企业地址', trigger: 'blur'},
        ],
        isCompany: [
          {required: true, message: '请选择是否为企业', trigger: 'change'},
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
        ],
        amount: [
          {required: true, message: '请输入开票金额', trigger: 'blur'},
        ],
        email: [
          {required: true, message: '请输入QQ邮箱', trigger: 'blur'},
        ],
      },
      total: 0, //分页
      // 开票数据
      tableData: [],
      tableData2: [], // 开票记录数据
      tableData3: []
    };
  },
  created() {
    this.getBasicData();
    this.getWaitingList();
  },
  methods: {
    showOrderNo(row) {
      let orderNos = "";
      row.targets.forEach(item => {
        orderNos += item.id + ",";
      });
      orderNos = orderNos.substring(0, orderNos.length - 1);
      if (orderNos.length > 50) {
        orderNos = orderNos.substring(0, 50) + "...";
      }
      return orderNos;
    },
    getWaitingList() {
      this.search = {
        page: 1,
        size: 10,
        total: 0,
        queryType: 3
      };
      getOrderList(this.search).then(res => {
        if (res.data.code === 0) {
          const {data} = res.data;
          this.search.total = data.total; // 共几条数据
          this.tableData = data.rows; // 绑定数据
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getBasicData() {
      basicData().then(res => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const data = res.data.data;
            console.log("basicData", data);
            this.basicData = data;
          } else {
            this.$message({
              type: "error",
              message: res.data.data.message,
            });
          }
        }
      });
    },
    // 搜索框
    handleClick() {
      if (this.activeName === 'first') {
        this.getWaitingList();
      } else if (this.activeName === 'second') {
        let status = "0,2"
        this.getDataList(status);
      } else if (this.activeName === 'third') {
        let status = "1"
        this.getFinishDataList(status);
      }
    },
    getDataList(status) {
      this.search = {
        page: 1,
        size: 10,
        total: 0,
        auditStatus: status
      };
      getInvoiceList(this.search).then(res => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const {data} = res.data;
            this.search.total = data.total; // 共几条数据
            this.tableData2 = data.rows; // 绑定数据
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    getFinishDataList(status) {
      this.search = {
        page: 1,
        size: 10,
        total: 0,
        auditStatus: status
      };
      this.search.auditStatus = status;
      getInvoiceList(this.search).then(res => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const {data} = res.data;
            this.search.total = data.total; // 共几条数据
            this.tableData3 = data.rows; // 绑定数据
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
            restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
        );
      };
    },
    loadAll() {
      return [
        {value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号"},
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        {value: "泷千家(天山西路店)", address: "天山西路438号"},
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
      ];
    },
    handleSelect(item) {
      console.log(item);
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    // 申请开票
    handleRequestInvoice() {
      //根据multipleSelection中的id来拼接targetsObj
      this.invoiceForm.targetsObj = [];
      this.invoiceForm.name = this.basicData.name;
      this.invoiceForm.email = this.basicData.email;
      this.invoiceForm.phone = this.basicData.phone;
      this.invoiceForm.idCard = this.basicData.idCard;
      this.invoiceForm.canSubmit = true;
      // multipleSelection 为空时提醒
      if (undefined === this.multipleSelection || this.multipleSelection.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择要开票的订单!",
        });
        return;
      }
      let amount = 0;
      console.log("selected", this.multipleSelection)
      this.multipleSelection.forEach((item) => {
        this.invoiceForm.targetsObj.push({
          id: item.orderId,
          productId: item.productId,
          name: item.productName,
          productPrice: item.productPrice,
          quantity: item.quantity,
          payPrice: item.payPrice,
        });
        amount += item.payPrice;
      });
      this.dialogVisible = true;
      this.invoiceForm.amount = amount;
    },
    handleViewInvoice(row) {
      this.dialogVisible = true;
      this.invoiceForm = {
        headerName: row.invoiceHeaderObj.headerName, //发票抬头
        isCompany: row.invoiceHeaderObj.invoiceHeader, //是否为单位
        taxNo: row.invoiceHeaderObj.taxNo, //税号
        name: row.invoiceHeaderObj.name, //姓名
        idCard: row.invoiceHeaderObj.idCard, //身份证号
        phone: row.invoiceHeaderObj.phone, //手机号
        bankAccount: row.invoiceHeaderObj.bankAccount, //银行账号
        bankName: row.invoiceHeaderObj.bankName, //开户行
        companyPhone: row.invoiceHeaderObj.companyPhone, //单位电话
        companyAddress: row.invoiceHeaderObj.companyAddress, //单位地址
        amount: row.amount, //开票金额
        email: row.invoiceHeaderObj.email, //邮箱
        remark: row.remark, //备注
        auditComment: row.auditComment, //审核意见
        targetsObj: row.targets, //开票对象
        auditRemark: row.auditRemark === null ? "" : row.auditRemark, //审核备注
        canSubmit: false, //是否可以提交
      }
      console.log("invoiceForm", this.invoiceForm)
    },
    submitForm() {
      submitInvoice(this.invoiceForm).then(res => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "申请成功!",
            });
            this.dialogVisible = false;
            this.getWaitingList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    resetForm() {
      this.dialogVisible = false;
      this.invoiceForm.name = this.basicData.name;
      this.invoiceForm.email = this.basicData.email;
      this.invoiceForm.phone = this.basicData.phone;
      this.invoiceForm.idCard = this.basicData.idCard;
      this.invoiceForm.amount = 0;
      this.invoiceForm.targetsObj = [];
    },
    formatData(val) {
      return null === val ? '' : val;
    },
    // 选中名称（取消选中）
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.invoice-form {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.invoice-form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoice-form__title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

.invoice-form__note {
  font-size: 14px;
  color: #999;
  margin-bottom: 0;
}

.invoice-form__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.el-button {
  margin-left: 10px;
}

.el-form-item__label {
  font-weight: bold;
}


.el-form-item__content {
  margin-left: 140px;
}

.el-form-item--feedback {
  margin-bottom: 20px;
}

.el-form-item__error {
  font-size: 12px;
  color: #f56c6c;
}

.invoice-form__table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.invoice-form__table th,
.invoice-form__table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.invoice-form__table th {
  background-color: #eee;
}

.invoice-form__table tr:hover {
  background-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .invoice-form__table th,
  .invoice-form__table td {
    padding: 6px;
  }
}

.invoice {
  em {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .my-autocomplete {
    float: left;
    padding-bottom: 20px;

    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .addr {
        color: #ddd;
      }
    }
  }

  .pagination {
    margin-top: 30px;
  }

  .red {
    color: red
  }
}
</style>